import React from "react";
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout';
import PrivacyPolicy from '../components/terms/privacy-policy'

const PrivacyPage=({ location }) => {
    
    return(
        <>
            <Helmet>
                <title>GoWrite&trade; Privacy Policy</title>
                <meta name="description" content="We Take your Privacy & Data Seriously" />
        
            </Helmet>
            <Layout location={location}>
                <Container className="py-3">
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <h1 className="heading mb-1">Privacy Policy</h1>
                            <h2 className="bodyText highlight mb-2">V2 - Last updated: August 2021</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <PrivacyPolicy cssClass="legal" />
                        </Col>
                    </Row>
                </Container>  
            </Layout>
        </>
    )
}

export default PrivacyPage